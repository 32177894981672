.navbar {
  margin-bottom: 0px !important;
  border-radius: 0px !important;
  border: none !important;
  box-shadow: 0px 5px 10px rgba(99, 115, 129, 0.02) !important;
  /* Icon 4 */
}
.navbar a {
  font-family: sofiapromedium;
  font-size: 15px !important;
}
.navbar .navbar-signup-btn {
  border-radius: 0px !important;
  height: 49px !important;
  width: 120px !important;
}
.navbar .navbar-signup-btn_ {
  background-color: #fff !important;
  color: #000 !important;
  border-radius: 0px !important;
  height: 49px !important;
  width: 120px !important;
}
.navbar .cursor {
  cursor: pointer;
}
.navbar #nav-icon4 {
  width: 25px;
  z-index: 10000000000000000000000;
  position: fixed;
  top: 20px;
  right: 20px;
  margin: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
}
.navbar #nav-icon4 span {
  display: block;
  height: 4px;
  width: 100%;
  margin: 0 0 4px 0;
  background: #e84671;
  border-radius: 9px;
  opacity: 1;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}
.navbar #nav-icon4 span:nth-child(1) {
  top: 0px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}
.navbar #nav-icon4 span:nth-child(2) {
  top: 8px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}
.navbar #nav-icon4 span:nth-child(3) {
  top: 16px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}
.navbar #nav-icon4.open span:nth-child(1) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  position: relative;
  top: 0px;
  left: 0px;
  width: 104%;
}
.navbar #nav-icon4.open span:nth-child(2) {
  width: 0%;
  opacity: 0;
}
.navbar #nav-icon4.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  position: relative;
  top: 2px;
  left: 0px;
  width: 104%;
}
.navbar .mobile-links {
  display: none;
}
.navbar .mobile-nav {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(2, 2, 2, 0.68);
  border: 1px solid #eee;
  z-index: 100000000;
}
.navbar .mobile-nav .inside {
  float: right;
  height: 100%;
  background-color: #fff;
  transition: linear all 0.3s;
  padding: 100px 30px;
}
.navbar .mobile-nav .inside .ui.vertical.menu {
  width: 100% !important;
}
.navbar .mobile-nav .inside .item {
  color: #212B36 !important;
}
.navbar .mobile-nav .inside .burger {
  padding-top: 12px;
  float: right;
}
.navbar .mobile-nav .hr {
  border: 0 !important;
  border-bottom: 1px solid #eee;
}
.navbar .hide {
  display: none;
}
.navbar .display {
  display: block;
  transition: linear all 0.1s;
}
.navbar .none {
  width: 0;
}
.navbar .block {
  width: 80%;
}
@media only screen and (max-width: 480px) {
  .navbar {
    box-shadow: 0px 1px 20px 0 rgba(34, 36, 38, 0.15) !important;
    position: fixed !important;
    top: 0 !important;
    min-width: 100% !important;
    z-index: 100000 !important;
  }
  .mobile-links {
    display: block !important;
  }
  .navbar-signup-btn {
    border-radius: 0px !important;
    height: 49px !important;
    width: 120px !important;
    font-size: 15px !important;
  }
  .inside img {
    display: inline !important;
  }
  .name {
    color: #212B36 !important;
  }
}
