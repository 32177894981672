#__next .home-banner {
  background-color: #1f0e03;
  background-image: url(/static/images/new-b.jpg) !important;
  background-position-x: 100%;
  background-size: auto 100%;
  min-height: 700px;
  margin: 0px;
  background-repeat: no-repeat;
  border-radius: 0px;
}
#__next .home-banner .ui.header {
  font-family: freightproblack;
  color: white;
  font-size: 45px;
  margin: 0px;
  line-height: 50px;
}
#__next .home-banner .sub.header {
  font-family: sofiapromedium;
  line-height: 28px;
  color: white;
  font-size: 20px;
}
#__next .home-banner .header-container {
  margin-bottom: 20px;
}
#__next .home-banner .subheader-container {
  margin-bottom: 40px;
}
#__next .home-banner .navv {
  width: 100%;
  background-color: transparent;
  height: 100px;
  color: #fff;
  position: absolute;
  top: 0;
}
#__next .home-banner .navv .ui.menu {
  background-color: transparent !important;
  color: #fff !important;
  box-shadow: none !important;
  border: 0 !important;
}
#__next .home-banner .navv .ui.menu .item {
  color: #fff;
}
@media only screen and (max-width: 798px) {
  #__next .home-banner {
    margin-top: -260px;
    min-height: 950px !important;
    padding-top: 370px;
    background-position-x: 50%;
    background-repeat: no-repeat !important;
    top: 0px;
  }
  #__next .home-banner .searchForm .date--picker img {
    left: -5px;
  }
  #__next .home-banner .searchForm .container {
    padding: 0px 22px;
  }
  #__next .home-banner .header-container {
    margin-bottom: 30px;
  }
  #__next .home-banner .subheader-container {
    margin-bottom: 65px !important;
  }
  #__next .home-banner .ui.container {
    margin: 0px !important;
  }
  #__next .home-banner .grid {
    height: 380px !important;
  }
  #__next .home-banner h4 {
    margin: 34px 18px !important;
  }
  #__next .home-banner .ui.header {
    font-family: freightproblack !important;
    font-size: 26px !important;
    margin: 0px 40px !important;
    line-height: 30px !important;
  }
  #__next .home-banner .header-container {
    margin-bottom: 10px !important;
    margin-top: -60px !important;
  }
  #__next .home-banner .sub.header {
    font-family: sofiapromedium !important;
    line-height: 20px !important;
    margin: 0px 35px !important;
    font-size: 15px !important;
  }
  #__next .home-banner .navv {
    width: 100%;
    background-color: #000000c0 !important;
    height: 60px !important;
    color: #fff;
    position: fixed !important;
    top: 0;
    z-index: 10000000000000000000 !important;
  }
  #__next .home-banner .navv .ui.menu {
    background-color: transparent !important;
    color: #fff !important;
    box-shadow: none !important;
    border: 0 !important;
  }
  #__next .home-banner .navv .ui.menu .item {
    color: #3a3a3a !important;
  }
}
