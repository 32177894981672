.howItWorks {
  background-color: rgba(232, 70, 113, 0.02) !important;
  padding-bottom: 100px !important;
}
.howItWorks .howItWorks-header {
  font-family: fontfreightproblack !important;
  margin: 34px 0px !important;
  margin-bottom: 40px !important;
  font-size: 32px !important;
}
.howItWorks .row {
  margin-bottom: 50px !important;
}
.howItWorks button {
  height: 60px !important;
  width: 197px !important;
  margin: 40px 0px 10px 0px !important;
}
@media only screen and (min-width: 1440px) {
  .howItWorks .container {
    padding: 0px 35px !important;
  }
}
@media only screen and (max-width: 480px) {
  .howItWorks .step {
    display: flex !important;
    justify-content: center !important;
    flex-direction: column !important;
    align-items: center !important;
    text-align: center !important;
  }
}
