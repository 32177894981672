#__next .searchForm input {
  height: 60px;
  width: 95% !important;
  border: none !important;
  font-size: 16px;
}
#__next .searchForm .container {
  background: white;
}
#__next .searchForm button {
  height: 60px;
  width: 90%;
}
#__next .searchForm img.ui.image {
  width: 17px !important;
  height: 17px !important;
  position: absolute;
  top: 23px;
  left: -5px;
}
#__next .searchForm .date--picker img.ui.image {
  position: absolute !important;
  top: 29px;
  left: -9% !important;
  height: 16px !important;
}
@media only screen and (max-width: 580px) {
  #__next .searchForm input {
    width: 91% !important;
  }
  #__next .searchForm .date--picker img.ui.image {
    left: -4% !important;
  }
}
@media only screen and (max-width: 796px) {
  #__next .searchForm .date--picker img.ui.image {
    left: -1% !important;
  }
}
@media only screen and (min-width: 796px) {
  #__next .searchForm .date--picker img.ui.image {
    left: -2% !important;
  }
}
@media only screen and (min-width: 796px) {
  #__next .searchForm .date--picker img.ui.image {
    left: -8% !important;
  }
}
@media only screen and (max-width: 480px) {
  #__next .searchForm {
    box-shadow: 0px 4px 20px 0 rgba(34, 36, 38, 0.15) !important;
    background: white !important;
    z-index: 3;
    width: 100vw;
  }
  #__next .searchForm .date--picker img.ui.image {
    left: -2% !important;
  }
  #__next .searchForm .ant-input {
    padding-left: 0px;
    font-size: 16px;
    font-family: sofiaproregular;
  }
  #__next .searchForm .container {
    margin: 0px 0px !important;
    width: 100% !important;
  }
}
@media only screen and (min-width: 1440px) {
  #__next .searchForm .ui.container {
    width: 100% !important;
  }
}
@media only screen and (min-width: 1200px) {
  #__next .searchForm .ui.container {
    width: 900px !important;
  }
}
