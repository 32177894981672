.footer {
  color: white !important;
  font-family: freightproblack !important;
  background-color: #212B36 !important;
  min-height: 471px !important;
  padding-top: 60px !important;
  margin-bottom: 0 !important;
}
.footer .divider {
  margin-top: 40px !important;
}
.footer .copyright {
  color: white !important;
}
.footer .copyright span {
  font-weight: 100 !important;
  font-size: 14px !important;
}
.footer .copyright img {
  margin-top: 0px !important;
  height: 18px !important;
}
.footer .social-links {
  margin-left: -12px !important;
}
.footer .social-links img {
  height: 18px !important;
  transition: linear all 0.3s;
}
.footer .social-links img:hover {
  height: 22px !important;
}
.footer .social-links .item {
  width: 40px !important;
  padding: 0px !important;
  padding-left: 0px !important;
  margin: 0px !important;
  margin-left: 0px !important;
}
.footer .ui.link.list.list a.item:hover,
.footer .ui.link.list.list .item a:not(.ui):hover {
  color: rgba(255, 255, 255, 0.8) !important;
  margin-bottom: 13px !important;
  font-size: 16px;
}
.footer .ui.link.list .item,
.footer .ui.link.list a.item,
.footer .ui.link.list .item a:not(.ui) {
  color: white !important;
  margin-bottom: 13px !important;
  font-size: 16px;
}
.footer .header {
  margin-bottom: 27px;
  font-size: 18px;
}
.footer .cookie {
  background-color: white  !important;
  color: #637381 !important;
  font-size: 16px  !important;
  font-family: sofiaproregular;
  position: fixed !important;
  line-height: 24px  !important;
  z-index: 100000  !important;
  bottom: 0  !important;
  left: 0;
  box-shadow: #4d4d4d 0px 2px 3px 2px  !important;
  padding: 10px  !important;
}
@media only screen and (max-width: 798px) {
  .footer .copyright {
    padding: 30px !important;
    margin: 0 !important;
  }
  .footer .cookie {
    font-size: 12px;
    position: fixed !important;
    line-height: 24px;
    z-index: 100000;
    bottom: 0;
  }
}
