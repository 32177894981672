@font-face {
  font-family: sofiaproblack;
  src: url('/static/fonts/sofiapro/Sofia\ Pro\ Black.ttf');
}
@font-face {
  font-family: sofiaproblackitalic;
  src: url('/static/fonts/sofiapro/Sofia\ Pro\ Black\ Italic.ttf');
}
@font-face {
  font-family: sofiaproregular;
  src: url('/static/fonts/sofiapro/Sofia\ Pro\ Regular.ttf');
}
@font-face {
  font-family: sofiapromedium;
  src: url('/static/fonts/sofiapro/Sofia\ Pro\ Medium.ttf');
}
@font-face {
  font-family: sofiapro;
  src: url('/static/fonts/sofiapro/sofiapro-light.otf');
}
@font-face {
  font-family: sofiaprosemibold;
  src: url('/static/fonts/sofiapro/Sofia\ Pro\ SemiBold.ttf');
}
@font-face {
  font-family: fontfreightproblack;
  src: url('/static/fonts/Freight\ Big\ Pro/FreightBigProBlack.otf');
}
@font-face {
  font-family: fontfreightbigproblack;
  src: url('/static/fonts/Freight\ Big\ Pro/Freight\_Big\_Pro\ Bold.otf');
}
body {
  font-family: sofiaproregular !important;
}
body ::-webkit-scrollbar-track {
  background: transparent !important;
  border-radius: 10px !important;
  cursor: pointer !important;
}
#__next {
  /* Mobile */
}
#__next .ui.progress {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 12345678787787;
}
#__next .ui.progress .bar {
  height: 5px !important;
  margin: 0 !important;
}
#__next .ui.progress:first-child {
  margin: 0 !important;
}
#__next .ui.pink.progress .bar {
  background-color: #e84671 !important;
}
@media screen and (max-width: 767px) {
  #__next .ui.progress .bar {
    height: 7px;
  }
}
#__next .is-v-centered {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#__next .is-lv-centered {
  display: flex;
  justify-content: left;
  align-items: center;
}
#__next .is-h-centered {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
#__next .pr-0 {
  padding-right: 0px !important;
}
#__next .pr-10 {
  padding-right: 10px !important;
}
#__next .pl-2 {
  padding-left: 2px !important;
}
#__next .pr-20 {
  padding-right: 20px !important;
}
#__next .pl-0 {
  padding-left: 0px !important;
}
#__next .pb-0 {
  padding-bottom: 0px !important;
}
#__next .pb-15 {
  padding-bottom: 15px !important;
}
#__next .pl-10 {
  padding-left: 10px !important;
}
#__next .pt-3 {
  padding-top: 3px !important;
}
#__next .pt-10 {
  padding-top: 10px !important;
}
#__next .pl-20 {
  padding-left: 20px !important;
}
#__next .has-text-aligned-right {
  text-align: end !important;
}
#__next .is-flex {
  display: flex !important;
}
#__next .full {
  height: 100% !important;
  width: 100% !important;
}
#__next .has-full-width {
  width: 100% !important;
}
#__next .has-height-80 {
  height: 80% !important;
}
#__next .has-height-10 {
  height: 10% !important;
}
#__next .has-width-95 {
  width: 95% !important;
}
#__next .has-font-freight {
  font-family: freightproblack !important;
}
#__next .has-font-freightbigpro {
  font-family: fontfreightbigproblack !important;
}
#__next .no-border {
  border: none !important;
}
#__next .no-box-shadow {
  box-shadow: none !important;
}
#__next .has-bg-white {
  background: white !important;
}
#__next .m-0 {
  margin: 0px !important;
}
#__next .mt-0 {
  margin-top: 0px !important;
}
#__next .mt-7 {
  margin-top: 7px !important;
}
#__next .mb-0 {
  margin-bottom: 0px !important;
}
#__next .mt-10 {
  margin: 10px !important;
}
#__next .mt-15 {
  margin: 15px !important;
}
#__next .mt-30 {
  margin-top: 30px !important;
}
#__next .mb-30 {
  margin-bottom: 30px !important;
}
#__next .mb-5 {
  margin-bottom: 5px !important;
}
#__next .mb-10 {
  margin-bottom: 10px !important;
}
#__next .mb-20 {
  margin-bottom: 20px !important;
}
#__next .mr-10 {
  margin-right: 10px !important;
}
#__next .mr-15 {
  margin-right: 15px !important;
}
#__next .mb-70 {
  margin-bottom: 70px !important;
}
#__next .is-grey {
  color: #637381 !important;
}
#__next .is-pink {
  color: #e84671 !important;
}
#__next .is-hidden {
  display: none !important;
}
#__next .fw100 {
  font-weight: 100 !important;
}
#__next .fw900 {
  font-weight: 900 !important;
}
#__next .fw600 {
  font-weight: 600 !important;
  font-family: sofiaproregular !important;
}
#__next .h80 {
  height: 80px !important;
}
#__next .h40 {
  height: 40px !important;
}
#__next .h28 {
  height: 28px !important;
}
#__next .h60 {
  height: 60px !important;
}
#__next .h720 {
  min-height: 720px !important;
}
#__next .h760 {
  min-height: 760px !important;
}
#__next .h690 {
  min-height: 690px !important;
}
#__next .w126 {
  width: 126px !important;
}
#__next .w215 {
  width: 215px !important;
}
#__next .is-round {
  border-radius: 100% !important;
}
#__next .h30 {
  height: 30px !important;
}
#__next .h10 {
  height: 10px !important;
}
#__next .p0 {
  padding: 0px !important;
}
#__next .p5 {
  padding: 5px !important;
}
#__next .pl-30 {
  padding: 30px !important;
}
#__next .progress-btn,
#__next .completed-btn {
  height: 40px !important;
  width: 160px !important;
  border: none !important;
}
#__next .progress-btn {
  background: rgba(235, 200, 17, 0.05) !important;
  color: #ebc811 !important;
}
#__next .cancelled-btn {
  background: rgba(235, 200, 17, 0.05) !important;
  color: #e84671 !important;
}
#__next .cancelled-btn:hover {
  filter: brightness(0.7);
}
#__next .completed-btn {
  background: rgba(38, 189, 35, 0.05) !important;
  color: #26bd23 !important;
}
#__next .booking-acceptBtn {
  border-radius: 0px;
  font-size: 14px;
  padding: 12px 30px !important;
  margin: 10px;
  background-color: #27ae60 !important;
  color: #fff !important;
}
#__next .booking-rejectBtn {
  border-radius: 0px;
  font-size: 14px;
  padding: 12px 30px !important;
  margin: 0 10px 10px 10px;
  background-color: #fff !important;
  color: #212b36 !important;
  border: 1px solid #c4cdd5 !important;
}
#__next .rejected-btn {
  background: rgba(235, 200, 17, 0.05) !important;
  color: #e84671 !important;
}
#__next .Btn {
  border-radius: 0px;
  font-size: 14px;
  padding: 12px 30px !important;
  margin: 10px;
  background-color: #27ae60 !important;
  color: #fff !important;
}
#__next .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
#__next .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
#__next .ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td,
#__next .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
  background: #eeeeee;
}
#__next .card {
  box-shadow: 0px 0px 10px rgba(99, 115, 129, 0.1);
}
#__next .text {
  color: #637381 !important;
}
#__next .ui.dropdown > .text {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  overflow: hidden;
}
#__next .modalContents {
  width: 100%;
  text-align: center !important;
}
#__next .dimmed.dimmable > .ui.animating.dimmer,
#__next .dimmed.dimmable > .ui.visible.dimmer,
#__next .ui.active.dimmer {
  z-index: 10000000 !important;
}
#__next .ui.divided.list > .item {
  border-top: 1px solid rgba(196, 205, 213, 0.25) !important;
}
#__next .ui.basic.buttons .button,
#__next .ui.basic.button {
  box-shadow: 0px 0px 0px 1px rgba(196, 205, 213, 0.5) inset !important;
}
#__next .ui.divided.list .list > .item:first-child,
#__next .ui.divided.list > .item:first-child {
  border-top: none !important;
}
#__next .ui.sticky {
  z-index: 1000000000000;
}
#__next .ui.loader:before {
  border: 0.2em solid rgba(174, 41, 41, 0.26) !important;
}
#__next .ui.loader:after {
  border-color: #e84671 transparent transparent !important;
}
#__next .ui.active.selection.dropdown {
  z-index: 10000000 !important;
}
#__next .auth {
  background: #020202 !important;
  min-height: 100vh !important;
  background-image: url('/static/images/auth.png') !important;
  background-size: cover !important;
}
#__next .logo {
  height: 50px !important;
  cursor: pointer !important;
}
#__next .fixed {
  position: fixed !important;
  top: 0 !important;
  width: 100% !important;
  box-shadow: 0px 0px 10px rgba(99, 115, 129, 0.1) !important;
}
#__next .ant-calendar-picker-input::placeholder {
  color: #637381 !important;
  font-weight: 500;
  font-family: sofiaproregular !important;
  font-size: 16px;
}
#__next .login-form {
  padding: 5px 25px !important;
}
#__next .login-form .login-form--input1 {
  margin: 10px 0px !important;
  margin-bottom: 30px !important;
}
#__next .login-form .login-form--input2 {
  margin: 10px 0px !important;
}
#__next .login-form .forgotPassword {
  font-family: sofiaprosemibold !important;
  color: #e84671;
  font-size: 16px;
  margin-top: 10px !important;
  margin-bottom: 20px !important;
  text-align: right !important;
  display: block !important;
}
#__next .login-form .column {
  background: white !important;
  padding: 45px 20px !important;
  margin-top: 4% !important;
  padding-bottom: 70px !important;
}
#__next .login-form .button {
  height: 60px !important;
  width: 126px !important;
  margin-bottom: 20px;
}
#__next .addressBook .mainBtn {
  border-radius: 0px;
  font-size: 14px;
  padding: 15px 30px !important;
  margin: 10px;
  background-color: #212b36 !important;
  font-size: 15px !important;
  font-weight: 400 !important;
  font-family: sofiapromedium !important;
  color: #fff !important;
  border: 1px solid #c4cdd580;
  margin-top: 35px !important;
}
@media only screen and (max-width: 480px) {
  #__next #login .column {
    width: 100% !important;
  }
  #__next #login form {
    padding: 5px 0px !important;
  }
}
@media only screen and (min-width: 1440px) {
  #__next .ui.container {
    margin-left: auto !important;
    margin-right: auto !important;
  }
}
@media only screen and (max-width: 767px) {
  #__next [class*='mobile hidden'],
  #__next [class*='tablet only']:not(.mobile),
  #__next [class*='computer only']:not(.mobile),
  #__next [class*='large monitor only']:not(.mobile),
  #__next [class*='widescreen monitor only']:not(.mobile),
  #__next [class*='or lower hidden'] {
    display: none !important;
  }
}
@media only screen and (max-width: 480px) {
  #__next .auth {
    background: none !important;
  }
  #__next .logo {
    height: 32px !important;
    width: 100%;
  }
}
