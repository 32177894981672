.testimonials--header {
  font-family: fontfreightproblack !important;
  margin: 34px 0px !important;
  margin-bottom: 40px !important;
  font-size: 32px !important;
}
.testimonials--next {
  position: absolute !important;
  right: 0%;
  top: 36%;
  cursor: pointer;
}
.testimonials {
  margin-bottom: 60px;
}
.testimonials .testimonials--avatar {
  height: 45px !important;
  width: 45px !important;
}
.testimonials .row {
  position: relative !important;
}
.testimonials .item {
  background: rgba(232, 70, 113, 0.02) !important;
  padding: 20px;
  height: 332px !important;
  padding: 50px 27px !important;
  display: flex;
  flex-direction: column;
  justify-content: start;
}
.testimonials .testimonials--testimony {
  font-family: sofiapromedium !important;
  height: 140px !important;
  min-height: 140px;
  max-height: 180px;
  overflow-y: hidden;
  text-overflow: ellipsis;
}
.testimonials .testimonials--fullname {
  margin-bottom: 4px !important;
}
.testimonials .owl-dots {
  margin-top: 30px !important;
}
.testimonials .owl-theme .owl-dots .owl-dot span {
  background: rgba(232, 70, 113, 0.25) !important;
}
.testimonials .owl-theme .owl-dots .owl-dot.active span,
.testimonials .owl-theme .owl-dots .owl-dot:hover span {
  background: #E84669 !important;
  height: 12px !important;
  width: 12px !important;
}
@media only screen and (max-width: 767px) {
  .testimonials .item {
    padding: 50px 50px !important;
  }
}
@media only screen and (min-width: 1440px) {
  #__next .testimonials .item {
    margin: 0px 2%;
    height: 400px !important;
    padding: 60px 55px !important;
  }
  #__next .testimonials .item .testimonials--testimony {
    line-height: 1.4em;
  }
}
