.moreInfo .header {
  font-family: fontfreightproblack !important;
  margin: 34px 0px 15px 0px !important;
  font-size: 32px !important;
}
.moreInfo .sub.header {
  font-size: 15px !important;
  color: #637381 !important;
  margin-bottom: 20px !important;
  font-family: sofiaproregular !important;
}
.moreInfo .row {
  background-color: rgba(232, 70, 113, 0.02) !important;
}
.moreInfo .button {
  height: 60px !important;
  width: 154px !important;
}
.moreinfo-text {
  width: 500px;
}
.more-info-img {
  max-height: 600px !important;
}
@media only screen and (max-width: 980px) {
  .moreinfo-text {
    width: 83%;
    margin: 70px 0px;
  }
}
